import { readonly, reactive } from "vue";
import axios from "axios";

let dev = true;

let appLog = (...args) => {
    if (dev) console.log(args);
};

let state = reactive({
    // message: {},
    notification: null,
    messageValid: false,
    messageLoading: false,
    canSendMessage: true,
    messageSent: null,
});

// eslint-disable-next-line no-unused-vars
let notify = async (msg) => {
    state.notification = msg;
    await sleep(3000);
    state.notification = null;
};

function sleep(millisecondsDuration) {
    return new Promise((resolve) => {
        setTimeout(resolve, millisecondsDuration);
    });
}

let methods = {
    async sendMessage(message) {
        let url = process.env.VUE_APP_API_CONTACT_URL;
        appLog(">>> sendMessage", url, message);

        // eslint-disable-next-line no-useless-catch
        // try {
            let resp = await axios.post(url, message);
            let data = await resp.data;
            appLog("<< data", data);
        // } catch (error) {
        //     // appLog("xx err", error);
        //     // notify("Oups, something went wrong");
        //     throw error;
        // }
        appLog("<< sendMessage");
    },
};

export default {
    state,
    methods: readonly(methods),
};
