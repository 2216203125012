<template>
    <div>
        <h1>Mini Project</h1>
    </div>
</template>

<script>
export default {
    name: 'MiniProject',
}
</script>

<style>

</style>