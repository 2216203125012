<template>
	<div id="home-page" class="h-full w-full">
		<main id="main" class="fixed left-0 top-0 h-full w-full z-50 bg-gray-900 overflow-x-hidden text-gray-400 md:text-gray-400 select-none">
			<section id="portfolio" class="radial-gradient absolute top-0 left w-full h-full">
				<div class="top-0 z-3 mx-auto px-10 md:px-0 md:w-11/12 xl:w-10/12 2xl:w-10/12 hidden md:flex justify-between items-start">
					<div id="links-social" class="relative z-50 flex flex-col justify-start items-center">
						<div class="h-10 w-0.5 bg-white mb-5"></div>
						<ul class="flex flex-col gap-3 items-center">
							<li v-for="(link, i) in links.socials" :key="i" class="transform hover:-translate-x-2 hover:text-white transition-all duration-300 ease-out">
								<a :href="link.url" :target="link?.stack == true ? '_self' : '_blank'">
									<ion-icon :name="link.icon" style="font-size: 32px"></ion-icon>
								</a>
							</li>
						</ul>
					</div>
					<div id="links-email" class="relative z-50 flex flex-col gap-5 justify-start ml-auto items-center -mr-12">
						<div class="h-10 w-0.5 bg-white"></div>
						<a :href="links.email.url" class="transform hover:translate-x-2 hover:text-white transition-all duration-300 ease-out">
							<ion-icon :name="links.email.icon" style="font-size: 32px"></ion-icon>
						</a>
						<a :href="links.email.url" class="transform rotate-90 mt-12 hover:translate-x-2 hover:text-white transition-all duration-300 ease-out">
							{{ links.email.text }}
						</a>
					</div>
				</div>
				<!-- Presentation and stuff -->
				<div class="absolute top-0 left-0 flex flex-col justify-between md:gap-3 py-10 px-5 h-full w-full overflow-hidden-">
					<nav id="mainNavbar" class="">
						<div class="cursor-normal p">
							<h1 @click="clearLogo" id="mainTitle" class="transition-all duration-100 ease-out text-6xl font-bold text-gray-300 hover:text-white transition-colors duration-200 ease-out mt-10 md:mt-0">
								<router-link to="/"> Amané Hosanna </router-link>
							</h1>
							<div id="mainSubtitle" class="transition-all duration-100 ease-out p-3 hover:text-white">
								<h3 class="text-xl text-gray-400 transition-colors duration-200 ease-out">I make websites and mobile apps</h3>
							</div>
						</div>

						<div class="hidden md:block transition-all duration-100 ease-out">
							<ul class="flex justify-center text-gray-300">
								<router-link
									:to="'/' + tab.id.toLowerCase()"
									v-for="(tab, i) in pageTabs"
									@click="selectPageTab(tab.id)"
									:key="i"
									class="px-3 py-2 bg-gray-900 bg-opacity-10 hover:bg-opacity-15 cursor-pointer border-2 border-transparent hover:text-white transition-all duration-200 ease-out"
									:class="[selectedPageTab == tab.id ? 'text-white font-medium bg-opacity-30 border-white' : '']"
									>{{ tab.title }}</router-link
								>
							</ul>
						</div>
					</nav>

					<main class="hidden md:block relative h-full overflow-hidden">
						<div class="h-full"
						:class="[(selectedPageTab == 'portfolio' && !selectedPortfolioProject) ? 'overflow-y-scroll' : '']"
						>
							<transition name="fade-up">
								<section
									v-show="selectedPageTab == 'portfolio'"
									id="cards"
									class="relative h-full w-9/12 lg:9/12 mx-auto grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:col-span-4 gap-5 pt-5">
									<a
										v-for="(project, i) in portfolioProjects"
										:key="i"
										@click.prevent="openPortfolioProject(project)"
										:id="'portfolio_' + project.id"
										:href="'/portfolio/' + project.id"
										:title="project.is_locked ? 'Project locked' : 'View project details'"
										:class="[project.is_locked ? 'cursor-not-allowed' : '']"
										class="group card border rounded border-opacity-20 p-3 flex flex-col gap-2 justify-around cursor-pointer">
										<div class="flex justify-center">
											<div v-if="project.is_locked">
												<ion-icon name="lock-closed-outline" class="text-white text-opacity-50" style="font-size: 64px"></ion-icon>
											</div>
											<img v-else :src="project.logo" class="w-24 h-24 object-contain rounded-xl" alt="" />
										</div>

										<h2 class="font-medium text-lg text-white">{{ project.name }}</h2>

										<div v-if="project.is_locked" class="relative px-10">
											<p class="text-white text-opacity-50">Project description is redacted</p>
											<div class="absolute top-0 left-0 w-full h-full bg-black flex justify-center items-center"></div>
										</div>
										<p v-else class="group-hover:text-gray-200 transition duration-300 ease-out">
											{{ project.description }}
										</p>
									</a>

									<PortfolioProjectDetailsCard v-show="isPortfolioProjectSelected" :project="selectedPortfolioProject" @closePortfolioProject="closePortfolioProject" />
								</section>
							</transition>

							<!-- <section></section> -->
							<transition name="fade-up">
								<section
									v-show="selectedPageTab == 'experience'"
									class="w-9/12 bg-white bg-opacity-10 border border-opacity-20 rounded mx-auto grid grid-cols-12 justify-center gap-5 px-5 lg:h-full">
									<div class="hidden lg:block col-span-2"></div>
									<aside class="col-span-4 lg:col-span-2">
										<ul class="text-right sticky top-0 py-5">
											<li
												v-for="(job, i) in workExperience"
												@click="selectJob(i)"
												:key="i"
												class="border-r-4 h-full"
												:class="[selectedJobIndex == i ? ' border-white text-white font-medium bg-gray-900 bg-opacity-30' : 'border-gray-900 border-opacity-20']">
												<router-link
													:to="`/experience/${i + 1}/${job.company.toLowerCase()}`"
													class="block py-2 px-3 pr-5 hover:bg-gray-900 hover:bg-opacity-20 hover:text-gray-200">
													{{ job.company }}
												</router-link>
											</li>
										</ul>
									</aside>
									<div class="col-span-8 text-left">
										<transition name="fade-up">
											<article v-if="selectedJob != null" class="h-full flex flex-col justify-between py-5">
												<div>
													<h3 class="text-xl font-medium text-white text-hover-white">
														{{ selectedJob.title }} <span class="">@ {{ selectedJob.company }}</span>
													</h3>
													<h5 class="ml-2 text-hover-white mt-2">{{ selectedJob.dates[0] }} - {{ selectedJob.dates[1] }}</h5>
												</div>
												<div>
													<ul class="job-descrition mt-3 flex flex-col gap-2">
														<li v-for="(task, j) in selectedJob.description" :key="j" class="text-hover-white">
															<span class="mr-2 text-white"><ion-icon name="remove-outline"></ion-icon></span>
															{{ task }}
														</li>
													</ul>
												</div>
												<ul class="flex gap-2 flex-wrap">
													<li v-for="(tech, j) in selectedJob.technologies" :key="j" class="text-hover-white">
														{{ tech }}
													</li>
												</ul>
											</article>
										</transition>
									</div>
								</section>
							</transition>

							<transition name="fade-up">
								<section v-show="selectedPageTab == 'mini_projects'" id="cards" class="w-9/12 mx-auto grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:col-span-4 gap-5 pt-5">
									<router-link
										:to="`/apps/${mini.id}`"
										v-for="(mini, i) in miniProjects"
										:key="i"
										class="group card border rounded border-opacity-20 p-3 flex flex-col gap-2 justify-around cursor-pointer">
										<div class="flex justify-center">
											<img :src="mini.logo" class="w-24 h-24 object-contain" alt="" />
										</div>

										<h2 class="font-medium text-lg text-white">{{ mini.name }}</h2>

										<p class="group-hover:text-gray-200 transition duration-300 ease-out">
											{{ mini.description }}
										</p>
									</router-link>
								</section>
							</transition>
						</div>
					</main>

					<div class="md:hidden flex flex-col items-center gap-3 text-gray-400">
						<a :href="links.email.url" class="transform hover:-translate-y-2 hover:text-white transition-all duration-300 ease-out">
							<ion-icon :name="links.email.icon" style="font-size: 64px"></ion-icon>
						</a>
						<ul class="flex gap-3 items-center">
							<li v-for="(link, i) in links.socials" :key="i" class="transform hover:-translate-y-2 hover:text-white transition-all duration-300 ease-out">
								<a :href="link.url" :target="link?.stack == true ? '_self' : '_blank'">
									<ion-icon :name="link.icon" style="font-size: 32px"></ion-icon>
								</a>
							</li>
						</ul>
						<a :href="links.email.url" class="hover:text-white transition-all duration-300 ease-out">
							{{ links.email.text }}
						</a>
					</div>

					<footer class="">
						<p class="flex items-center justify-center gap-1 text-gray-300 hover:text-white hover:text-lg transition-colors duration-200 ease-out">
							<span class="dash-note"></span>
							<span> Under Maintenance </span>
							<span class="dash-note"></span>
						</p>
					</footer>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
	import gsap from "gsap";
	// import ScrambleText from "gsap/ScrambleTextPlugin";

	// import { gsap } from "gsap-trial";

	/* The following plugin is a Club GSAP perk */
	// import { ScrambleTextPlugin } from "gsap-trial/ScrambleTextPlugin";

	// gsap.registerPlugin(ScrambleTextPlugin);

	import MatrixGreenRain from "../components/p5/MatrixGreenRain.vue";
	import PortfolioProjectDetailsCard from "../components/PortfolioProjectDetailsCard.vue";
	import perlin from "../helpers/perlin.js";

	export default {
		name: "Home",
		components: { MatrixGreenRain, PortfolioProjectDetailsCard },
		props: ["showFormContact"],
		//
		// inject: ["ss_contact"],
		data() {
			const _TYPES = {
				personal: {
					icon: "bulb-outline",
					name: "Personal",
				},
				startup: {
					icon: "rocket-outline",
					name: "Startup",
				},
				fun: {
					icon: "color-palette-outline",
					name: "For fun",
				},
				experiment: {
					icon: "flask-outline",
					name: "Experiment",
				},
				company: {
					icon: "business-outline",
					name: "Entreprise",
				},
				freelance: {
					icon: "briefcase-outline",
					name: "Freelance, Particulier",
				},
				particulier: {
					icon: "person-outline",
					name: "Freelance, Particulier",
				},
			};

			const _PLATFORMS = {
				web: {
					id: "web",
					icon: "globe-outline",
					name: "Web",
				},
				mobile: {
					id: "mobile",
					icon: "phone-portrait-outline",
					name: "Mobile",
				},
				desktop: {
					id: "desktop",
					icon: "desktop-outline",
					name: "Desktop",
				},
				embedded: {
					id: "embedded",
					icon: "hardware-chip-outline",
					name: "Embedded",
				},
				other: {
					id: "other",
					icon: "ellipsis-horizontal-circle-outline",
					name: "Other",
				},
			};

			return {
				selectedPortfolioProject: null,

				selectedPageTab: "portfolio",
				selectedJobIndex: 0,
				selectedMiniProjectId: 0,
				// selectedJob: null,
				showContactPage: false,
				showContactForm: false,
				showMessageForm: false,
				showAboutPage: false,
				// ? Displays map where to find me
				showMiniMap: false,
				showStartupDetails: false,
				message: {
					name: null,
					email: null,
					content: null,
					type: {
						web: false,
						mobile: false,
						other: false,
					},
				},
				errors: {
					message: {
						name: "",
						email: "",
						content: "",
						type: "",
					},
					contact: null,
				},
				messageValid: false,
				messageLoading: false,
				canSendMessage: true,
				messageSent: null,

				pageTabs: [
					{
						id: "portfolio",
						title: "Portfolio",
					},
					// {
					// 	id: "mini_projects",
					// 	title: "Mini projects",
					// },
					// {
					// 	id: "experience",
					// 	title: "Experience",
					// },
				],

				portfolioProjects: [
					{
						id: "code-de-la-route",
						name: "Code de la Route",
						description: "Learn and train yourself for the highway code exam in Ivory Coast",
						full_description: `Est-ce que vous maitrisez le Code de la Route ? Apprennez les panneaux de signalisation, et testez vos connaissances à travers une série de questions variés et interactives.  Que vous soyez un conducteur débutant cherchant à renforcer vos compétences ou un conducteur expérimenté désirant rafraîchir vos connaissances, notre application offre une expérience d'apprentissage engagéante et enrichissante.  Préparez-vous à relever le défi et à devenir un expert du Code de la Route !`,
						logo: "/logo/logo_code_de_la_route.png",
						type: _TYPES.personal,
						platform: _PLATFORMS.mobile,
						features: [
							"Firebase auth",
							"Authentication",
							"Learning module for traffic signs",
							"Interactive quiz module with diverse questions",
							"User segmentation based on experience level",
							"Engaging and enriching learning experience",
							"Challenge mode to become an expert in the Highway Code",
						],

						screenshots_data: {
							count: 34,
							base_url: "/images/code_de_la_route_mobile/",
							ext: ".jpg",
						},
						tech_stack: ["flutter", "firebase", "API", "MySQL", "Web Scraping"],
						links: [
							{
								provider: "playstore",
								url: "https://play.google.com/store/apps/details?id=dev.amane.codedelaroute",
							},
						],
					},
					{
						id: "freeflow",
						name: "FreeFlow",
						description: "Manage your projects, invoices, payments and progress as a Freelancer",
						full_description:
							"Manage your projects, invoices, payments, and progress as a Freelancer with ease and efficiency. Stay organized and in control of your work by utilizing powerful project management tools, tracking your time and expenses, and generating professional invoices. Keep track of your payments and monitor your progress to ensure you are meeting your goals and delivering high-quality work to your clients. With this comprehensive solution, you can streamline your freelance business and focus on what you do best - delivering exceptional results.",
						logo: "/logo/logo_flexflow_full.png",
						type: _TYPES.personal,
						technologies: ["Laravel", "Vue", "API", "MySQL"],
						links: [
							{
								internal: true,
								icon: "planet-outline",
								to: { name: "AppFreeFlow" },
							},
						],
						type: _TYPES.personal,
						platform: _PLATFORMS.web,
						hide_features: true,
						screenshots_data: {
							count: 5,
							base_url: "/images/flexflow/",
							ext: ".png",
						},
						tech_stack: ["vuejs", "nuxtjs", "API", "MySQL"],
						links: [
							{
								provider: "web",
								url: "https://flexflow.amane.dev",
							},
						],
					},
					{
						id: "faniko",
						name: "Faniko",
						description: "Laundry management system, invoices, payments and client waitlist",
						full_description:
							"Gérer plusieurs établissements ainsi qu’une usine n’a jamais été si simple. FANIKO regroupe tous vos établissements et vous permet de gérer d’un même endroit leurs performances ainsi que le processus de nettoyage.",
						logo: "/logo/logo_faniko.png",
						type: _TYPES.personal,
						platform: _PLATFORMS.web,
						hide_features: true,
						screenshots_data: {
							count: 5,
							base_url: "/images/faniko/",
							ext: ".png",
						},
						tech_stack: ["laravel", "vuejs", "API", "MySQL", "firebase"],
						links: [
							{
								provider: "web",
								url: "https://fanikopro.com/",
							},
						],
					},

					{
						name: "Mikassa",
						description: "// TODO fetch project details",
						logo: "",
						is_locked: true,
						type: _TYPES["personal"],

						technologies: ["Laravel", "Flutter", "API", "MySQL"],
						links: [
							{
								icon: "",
								url: "",
							},
						],
					},

					{
						id: "ici",
						name: "ICI",
						description: "Find the nearest places around you",
						full_description:
							"Find the nearest places around you. ICI is an app that allows you to discover and navigate to the closest points of interest. With features like user authentication, user reviews and ratings, user profile management, and more, ICI provides a seamless experience for users. Utilizing geolocation and map integration, users can easily search for places, plan itineraries, and receive real-time traffic information. Additionally, ICI offers location sharing functionality, allowing users to share their current location with others. Download ICI now from the Play Store!",
						logo: "/logo/logo_ici.png",
						type: _TYPES.personal,
						platform: _PLATFORMS.mobile,
						hide_features: true,
						features: [
							"User authentication",
							"User reviews and ratings",
							"User profile management",
							"User notifications",
							"Geolocation API",
							"Map integration",
							"Places API",
							"Map search",
							"Map navigation",
							"Itinerary planning",
							"Real-time traffic information",
							"Location sharing",
						],
						screenshots_data: {
							count: 37,
							base_url: "/images/ici/",
							ext: ".jpeg",
						},
						tech_stack: ["flutter", "nodejs", "arangodb", "fastifyjs", "API", "Google APIS", "Google Directions API", "Places API"],
						links: [
							{
								provider: "playstore",
								url: "https://play.google.com/store/apps/details?id=com.joolinternational.ici",
							},
						],
					},
					//
					{
						id: "dix",
						name: "Dix",
						description: "Transition to 10 digits of Ivorian phone numbers during the PNN",
						full_description: "Transition to 10 digits of Ivorian phone numbers during the PNN",
						logo: "/logo/logo_dix.png",
						type: _TYPES.company,
						platform: _PLATFORMS.mobile,
						hide_features: true,
						screenshots_data: {
							count: 9,
							base_url: "/images/dix/",
							ext: ".jpg",
						},
						tech_stack: ["laravel", "flutter", "API", "MySQL", "firebase", "firestore"],
						links: [
							{
								provider: "playstore",
								url: "https://play.google.com/store/apps/details?id=com.adjemin.dix",
							},
						],
					},

					{
						name: "D24 News",
						description: "//TODO fetch details",
						logo: "/logo/logo_d24.png",
						type: _TYPES.freelance,
						description: "News app",
						full_description:
							"Application d'information et journal quotidien. Les dernières nouvelles en Côte d'Ivoire et dans le monde. Restez informé avec D24 News. Téléchargez l'éditorial quotidien et faites votre séléction d'articles que vous voulez lire",
						platform: _PLATFORMS.mobile,
						hide_features: true,
						screenshots_data: {
							count: 9,
							base_url: "/images/d24/",
							ext: ".jpg",
						},
						technologies: ["Laravel", "Flutter", "API", "MySQL"],
					},
					{
						id: "ccc",
						name: "CCC - Comptage Café Cacao",
						description: "Coffee and Cocoa Counting App",
						full_description:
							"CCC - Comptage Café Cacao is a Coffee and Cocoa Counting App that offers offline mode, real-time data synchronization, multi-user support, and authentication.",
						image: "https://drive.google.com/file/d/1RKn4-AKKPa1qV6A2RoU52QRc6NHfEmPF/view?usp=share_link",
						type: _TYPES["freelance"],
						logo: "/logo/logo_ccc.png",
						features: ["offline mode", "real-time data synchronization", "multi-user support", "authentication"],
						platforms: ["mobile"],
						technologies: ["Laravel", "Flutter", "API", "MySQL"],
						screenshots_data: {
							count: 7,
							base_url: "/images/ccc/",
							ext: ".jpeg",
						},
					},
					{
						id: "envoy",
						name: "Envoy",
						description: "Delivery app",
						full_description: "Another delivery app",
						logo: "/logo/logo_envoy.png",
						type: _TYPES.freelance,
						platform: _PLATFORMS.mobile,
						features: [
							"Real-time parking spot availability",
							"User location tracking",
							"User reviews and ratings",
							"User authentication",
							"User profile management",
							"User notifications",
							"Map integration",
							"Map navigation",
						],
						// screenshots_data: {
						// 	count: 5,
						// 	base_url: "/images/envoy/",
						// 	ext: ".jpeg",
						// },
						tech_stack: ["laravel", "flutter", "API", "MySQL"],
					},
				],
				miniProjects: [
					{
						name: "Estimate Your App",
						description: "How much does your web/mobile app cost ? Find out with with webapp",
						image: "https://drive.google.com/file/d/1RKn4-AKKPa1qV6A2RoU52QRc6NHfEmPF/view?usp=share_link",
						type: _TYPES["personal"],

						platforms: ["mobile"],
						technologies: ["Laravel", "Vue", "API", "MySQL"],
						links: [
							{
								target: "_self",
								icon: "planet-outline",
								url: "/apps/app-estimation",
							},
						],
					},

					{
						name: "Chic Dame Makeup",
						description: "// TODO fetch project details",
						logo: "/logo/logo_chic_dame_makeup.png",
						type: _TYPES["particulier"],

						technologies: ["Laravel", "Flutter", "API", "MySQL"],
						links: [
							{
								icon: "",
								url: "",
							},
						],
					},
				],
				projectsFeatured: [
					{
						name: "FreeFlow",
						description:
							"You're a freelancer, you need to manage your projects, invoices, payments ? FreeFlow lets you do that. \n You're a client, you want to see your project's progress ? FreeFlow lets you do that",
						image: "https://play-lh.googleusercontent.com/nql4-7ancTZpCPiGhShvW2jRC8-kzOixL6HmPnFUM7qRzcfa1OhqbhRkrOQskijb7rEh=w1052-h592-rw",
						type: _TYPES.personal,
						technologies: ["Laravel", "Vue", "API", "MySQL"],
						links: [
							{
								internal: true,
								icon: "planet-outline",
								to: { name: "AppFreeFlow" },
							},
						],
					},
					{
						name: "Dix",
						description: "//TODO fetch project details",
						image: "https://play-lh.googleusercontent.com/nql4-7ancTZpCPiGhShvW2jRC8-kzOixL6HmPnFUM7qRzcfa1OhqbhRkrOQskijb7rEh=w1052-h592-rw",
						type: _TYPES.company,
						technologies: ["Laravel", "Flutter", "API", "MySQL", "Firebase", "Firestore"],
						links: [
							{
								icon: "logo-google-playstore",
								url: "https://play.google.com/store/apps/details?id=com.adjemin.dix",
							},
						],
					},

					{
						name: "ICI",
						description: "//TODO fetch project details",
						image: "",
						technologies: ["Flutter", "NodeJS", "ArangoDB", "FastifyJS"],
						links: [
							{
								icon: "",
								url: "",
							},
						],
					},
				],
				workExperience: [
					{
						title: "Lead Developer",
						company: "Edane",
						dates: ["Jan 2023", "March 2024"],
						description: [
							"Digitization of loan management systems from major banks",
							"Integration of SOAP APIs for central banking systems",
							"Led the launch of a POC: configuration of linux servers and deployment of projects via VPN while ensuring good email communications between both tech teams and upper management",
							"Wrote server-side utility scripts",
							"Development and release of end-to-end features: from design to database modeling, from coding to testing to deployment",
						],
						technologies: ["Django/Python", "MongoDB", "Laravel/PHP", "VueJS", "NodeJS", "Javascript", "Bash", "PhP"],
						projects: [
							{
								name: "",
								link: "",
								description: "",
								icon: "",
								image: "",
							},
						],
					},
					{
						title: "Project Manager",
						company: "JooL",
						dates: ["March 2021", "December 2023"],
						description: [
							"Parallel management of multiple projects directly with the CEO",
							"Created a mobile maps application for address generation ",
							"Worked with embedded RaspBerry systems for automated drones and a mechanical box remote server",
							"Designed and built an internal web application for task and project management",
							"Developed a web app for downloading and managing various versions of internal mobile apps alpha releases",
							"Created an internal API to calculate the health of a plant using agricultural data",
						],
						technologies: [
							"NodeJS/Fastify",
							"Flutter/Dart",
							"Quasar/VueJS",
							"MapBox",
							"Docker",
							//
							"PHP",
							"PostgreSQL",
							"C++",
							//
							"VueJS/Typescript",
							"Fastify/Node",
							"ArangoDB",
							//
						],
						projects: [
							{
								name: "",
								link: "",
								description: "",
								icon: "",
								image: "",
							},
						],
					},
					{
						title: "Lead Developer",
						company: "Adjemin",
						dates: ["April 2020", "Feb 2021"],
						description: [
							"Developed from scratch the first version of the AdjeminPay aggregator: API, PHP/Javascript SDK, Integrated  OrangeMoney/MTN Money APIs, Admin Dashboard, Merchant Dashboard, mobile money customer payment page and documentation",
							"Created ecommerce payment plugins for Woocommerce and mobile applications",
							"Built and deployed the Dix mobile application for the transition to 10 digits of Ivorian phone numbers during the PNN",
							"Developed websites and mobile applications",
						],
						technologies: [
							"Laravel/PhP",
							" Javascript ",
							" HTML ",
							" CSS ",
							" Livewire ",
							" REST API ",
							" Reverse Engineering",
							//
							"Wordpress/PhP",
							"Flutter/Dart",
							//
							//
						],
						projects: [
							{
								name: "",
								link: "",
								description: "",
								icon: "",
								image: "",
							},
						],
					},
				],

				links: {
					email: {
						icon: "mail-outline",
						url: "mailto:hello@amane.dev",
						text: "hello@amane.dev",
					},
					socials: [
						{
							icon: "logo-linkedin",
							url: "https://www.linkedin.com/in/amane-dev/",
						},
						{
							icon: "logo-github",
							// url: "https://github.com/amane-dev",
							url: "https://github.com/Alloco225",
						},
						{
							icon: "logo-gitlab",
							url: "https://gitlab.com/Alloco225",
						},

						{
							icon: "logo-instagram",
							url: "https://www.instagram.com/amane.dev/",
						},

						// {
						// 	stack: true,
						// 	icon: "chatbox-outline",
						// 	url: "/contact",
						// },
					],
				},
			};
		},

		computed: {
			isPortfolioProjectSelected() {
				return this.selectedPortfolioProject != null;
			},
			selectedJob() {
				return this.workExperience[this.selectedJobIndex];
			},
		},
		created() {
			function debounce(func, timeout, timer) {
				return (...args) => {
					if (timer) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						func.apply(this, args);
					}, timeout);
				};
			}

			let perlinInterval = null;
			let time = 0;

			let isMouseMoving = false;

			const updateBackgroundPosition = () => {
				time += 0.05;

				if (isMouseMoving) return;

				var noiseX = perlin.noise.perlin2(time, 0);
				var noiseY = perlin.noise.perlin2(0, time);

				const mouseXpercentage = (noiseX + 1) * 50;
				const mouseYpercentage = (noiseY + 1) * 50;

				var background = document.querySelector(".radial-gradient");
				background.style.background = "radial-gradient(at " + mouseXpercentage + "% " + mouseYpercentage + "%, #205172, transparent)";
			};

			clearInterval(perlinInterval);

			perlinInterval = setInterval(updateBackgroundPosition, 100);

			let timer;

			const updateIdle = () => {
				isMouseMoving = false;
			};

			// timer = setTimeout(updateIdle, 3000);

			const updateBackgroundPositionPosition = (event) => {
				isMouseMoving = true;

				let windowWidth = window.innerWidth;
				let windowHeight = window.innerHeight;

				let mouseXpercentage = Math.round((event.pageX / windowWidth) * 100);
				let mouseYpercentage = Math.round((event.pageY / windowHeight) * 100);

				var background = document.querySelector(".radial-gradient");
				background.style.background = "radial-gradient(at " + mouseXpercentage + "% " + mouseYpercentage + "%, #205172, transparent)";
			};

			// document.addEventListener("mousemove", debounce(updateBackgroundPositionPosition, 5000), timer);
			// document.addEventListener("mousemove", updateBackgroundPositionPosition);

			//
			let cardContainer = document.querySelector("#cards");
			if (cardContainer) {
				cardContainer.onmousemove = (e) => {
					for (const card of document.getElementsByClassName("card")) {
						const rect = card.getBoundingClientRect(),
							x = e.clientX - rect.left,
							y = e.clientY - rect.top;

						card.style.setProperty("--mouse-x", `${x}px`);
						card.style.setProperty("--mouse-y", `${y}px`);
					}
				};
			}

			document.addEventListener("wheel", function (event) {
				// console.log("wheel", event);
				// const {deltaX, deltaY, clientX, clientY} = event;
			});
		},
		methods: {
			clearLogo() {
				console.log("clear logo");
				const title = document.getElementById("mainTitle");
				const subtitle = document.getElementById("mainSubtitle");

				// Animate the text morph
				// gsap.to(title, {
				// 	duration: 2,
				// 	scrambleText:{text:"あ", chars:"lowerCase", revealDelay:0.5, tweenLength:false},
				// 	ease: "power2.inOut",
				// });

				const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

				let interval = null;

				let iteration = 0;

				let fullValue = "Amané Hosanna";
				let minValue = "あ";

				gsap.to(subtitle, {
					duration: 0.3,
					translateY: -10,
					opacity: 0,
					ease: "power2.inOut",
				});
				subtitle.style.display = "none";

				title.innerText = fullValue === title.innerText ? minValue : fullValue;

				const navbar = document.getElementById("mainNavbar");
				const navbarClasses = "flex justify-between items-center mx-auto px-10 md:px-0 md:w-9/12";
				navbar.classList.add(...navbarClasses.split(" "));
			},
			showLogo() {
				const title = document.getElementById("mainTitle");
				const subtitle = document.getElementById("mainSubtitle");

				const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

				let interval = null;
				let iteration = 0;
				let fullValue = "Amané Hosanna";
				let minValue = "あ";

				const navbar = document.getElementById("mainNavbar");
				const navbarClasses = "flex justify-between items-center mx-auto px-10 md:px-0 md:w-9/12";
				navbar.classList.remove(...navbarClasses.split(" "));

				subtitle.style.display = "block";

				gsap.from(subtitle, {
					duration: 0.3,
					translateY: -10,
					opacity: 0,
					ease: "power2.inOut",
				});

				title.innerText = fullValue === title.innerText ? minValue : fullValue;
			},
			clearLogo_() {
				const title = document.getElementById("mainTitle");

				// Animate the text morph
				// gsap.to(title, {
				// 	duration: 2,
				// 	scrambleText:{text:"あ", chars:"lowerCase", revealDelay:0.5, tweenLength:false},
				// 	ease: "power2.inOut",
				// });

				const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

				let interval = null;

				let iteration = 0;

				let value = "Amané Hosanna";
				value = "あ";

				clearInterval(interval);

				interval = setInterval(() => {
					title.innerText = title.innerText
						.split("")
						.map((letter, index) => {
							if (index < iteration) {
								return value[index];
							}

							return letters[Math.floor(Math.random() * 26)];
						})
						.join("");

					if (iteration >= value.length) {
						clearInterval(interval);
					}

					iteration += 1 / 3;
					console.log("iteration", iteration);

					// if(iteration >= 5){
					// 	clearInterval(interval);
					// }
				}, 30);
			},
			async sleep(milliseconds) {
				return new Promise((resolve) => setTimeout(resolve, milliseconds));
			},
			closePortfolioProject() {
				this.selectedPortfolioProject = null;
				this.$router.go(-1);
				this.showLogo();
			},
			async openPortfolioProject(portfolio) {
				if (portfolio.is_locked) {
					// run shake animation and return;
					return;
				}
				this.$router.push("/portfolio/" + portfolio.id);
				// run animation;
				const elementId = "#portfolio_" + portfolio.id;
				const originalElement = document.querySelector(elementId);

				this.clearLogo();

				this.selectedPortfolioProject = portfolio;
				return;

				await this.sleep(1000);

				// Get the element's current position
				const rect = originalElement.getBoundingClientRect();
				const computedStyle = getComputedStyle(originalElement);

				// Get any margin offsets
				const marginTop = parseInt(computedStyle.marginTop);
				const marginLeft = parseInt(computedStyle.marginLeft);

				// Create a duplicate element
				const clone = originalElement.cloneNode(true);
				clone.style.position = "absolute";
				clone.style.zIndex = 9999;
				clone.style.top = rect.top + "px";
				clone.style.left = rect.left + "px";

				const cards = document.querySelector("#cards");
				cards.appendChild(clone);

				const element = clone;
				// Set the element to fixed and apply the current coordinates
				// gsap.set(element, {
				// 	position: "absolute",
				// 	top: rect.top - marginTop,
				// 	left: rect.left - marginLeft,
				// 	zIndex: 9999,
				// });

				// Animate the scaling
				gsap.to(element, {
					duration: 1,
					// scale: scale,
					backgroundColor: "#205172",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					ease: "power2.inOut",
				});
				// gsap.to(element, {
				// 	duration: 1,
				// 	// scale: scale,
				// 	backgroundColor: "#205172",
				// 	top: 0,
				// 	left: 0,
				// 	width: "100vw",
				// 	height: "100vh",
				// 	ease: "power2.inOut",
				// });

				// await this.$sleep(2000);
				// this.$router.push({ name: "PortfolioProject", params: { id: portfolio.id } });
			},
			selectJob(index) {
				this.selectedJobIndex = index;
				// this.selectedJob = null;
				// setTimeout(() => {
				// 	this.selectedJob = this.workExperience[this.selectedJobIndex];
				// }, 500);
			},
			selectPageTab(index) {
				console.log("selectPageTab", index);
				this.selectedPageTab = null;
				setTimeout(() => {
					this.selectedPageTab = index;
				}, 500);
			},
		},
	};
</script>

<style scoped>
	html,
	body {
		padding: 0;
		margin: 0;
		height: 100vh;
	}
	#hero {
		background-color: #fff;
	}
	#main {
		/* margin: 100vh 0 0 0; */
		padding: 0;
	}
	/* Hide scrollbar for Chrome, Safari and Opera */
	#main::-webkit-scrollbar {
		display: none;
	}

	.radial-gradient {
		background: radial-gradient(#205172, transparent);
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	#main {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	/* TODO add futuristic folder shape to projects inspiration: https://
kprverse.com/ */
	.folder {
		position: relative;
		with: "";
	}
	.shape-square {
		-webkit-clip-path: url(#shpSquare);
		clip-path: url(#shpSquare);
	}
	.shape-horizontal {
		-webkit-clip-path: url(#shpHorizontal);
		clip-path: url(#shpHorizontal);
	}
	.shape-vertical {
		-webkit-clip-path: url(#shpVertical);
		clip-path: url(#shpVertical);
	}
	.folder::before {
		content: "";
		width: 50%;
		height: 50px;
		border-radius: 5px 5px 0 0;
		background-color: inherit;
		position: absolute;
		top: -30px;
		right: 0px;
	}
	.foot-note {
		position: relative;
	}
	.foot-note::before {
		content: "";
		position: absolute;
		width: 10px;
		height: 1px;
		background-color: white;
		right: 0;
		top: 50%;
	}
	.dash-note {
		display: inline-block;
		width: 10px;
		height: 1px;
		background-color: white;
	}

	.text-hover-white:hover {
		transition: color 0.3s ease-out;
		color: white;
	}

	#cards:hover > .card::after {
		opacity: 1;
	}

	.card {
		position: relative;
		background-color: rgba(255, 255, 255, 0.1);
	}

	.card:hover::before {
		opacity: 1;
	}

	.card::before,
	.card::after {
		border-radius: inherit;
		content: "";
		height: 100%;
		left: 0px;
		opacity: 0;
		position: absolute;
		top: 0px;
		transition: opacity 500ms;
		width: 100%;
	}

	.card::before {
		background: radial-gradient(800px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.06), transparent 40%);
		z-index: 3;
	}

	.card::after {
		background: radial-gradient(600px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.4), transparent 40%);
		z-index: 1;
	}

	.card > .card-content {
		background-color: var(--card-color);
		border-radius: inherit;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		inset: 1px;
		padding: 10px;
		position: absolute;
		z-index: 2;
	}
</style>
