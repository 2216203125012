<template>
	<div id="matrix-rain-container"></div>
</template>

<script>
	const P5 = require("p5");
	export default {
		name: "MatrixRain",
		mounted() {
			console.log("MOUNTED");
			const script = function (p5) {
				var streams = [];
				var fadeInterval = 1.6;
				// var symbolSize = 14;
				var symbolSize = 20;
				let speedFactor = 1;

				var image;

				let imageSpots = [];

				p5.preload = () => {
					// image = p5.loadImage("/images/amane.png");
					image = p5.loadImage("/images/mask.png");
					console.log("preloaded", image.width);
				};

				p5.setup = (_) => {
					console.log("p5", _);
					let canvas = p5.createCanvas(window.innerWidth, window.innerHeight);
					canvas.id("matrix-rain-canvas");
					canvas.parent("matrix-rain-container");
					p5.background(0);
					p5.image(image, 0, 0, p5.width, p5.height);


					let w = p5.width / image.width;
					let h = p5.height / image.height;
					

					image.loadPixels();

					for (let j = 0; j < image.height; j++) {
						// let col = [];
						let row = [];
						for (let i = 0; i < image.width; i++) {
							const pixelIndex = (i + j * image.width) * 4;

							const r = image.pixels[pixelIndex + 0];
							const g = image.pixels[pixelIndex + 1];
							const b = image.pixels[pixelIndex + 2];
							const avg = (r + g + b) / 3;
							const brightness = p5.brightness(image.pixels[pixelIndex]);

							p5.strokeWeight(1);

							// p5.stroke(brightness);
							p5.stroke(avg);
							// p5.square(i * w + w * 0.5, j * h + h * 0.5);
							p5.point(i * w, j * h);

							row.push(brightness);
							// textSize(w * 1.2);
							// textAlign(CENTER, CENTER);

							// text(poem.charAt(charIndex % poem.length), i * w + w * 0.5, j * h + h * 0.5);
							// charIndex++;
						}
						//   col.push(row)
						imageSpots.push(row);
					}

					// for (let x = 0; x < image.width; x++) {
					//   let col = [];
					//   for (let y = 0; y < image.height; y++) {
					//     let index = x + y * image.width;
					//     let row = [];
					//     let c = image.pixels[index];
					//     let brightness = p5.brightness(c);
					//     if (brightness > 1) {
					//       row.push(parseInt(brightness));
					//       // imageSpots.push({x, y, brightness: parseInt(brightness)});
					//       // imageSpots.push(parseInt(brightness));
					//     }
					//     if (row.length > 0) col.push(row);
					//     else col.push(null);
					//   }
					//   if (col.length > 0) imageSpots.push(col);
					//   else imageSpots.push(null);
					// }

					for (let y = 0; y < imageSpots.length; y++) {
						const col = imageSpots[y];
						for (let x = 0; x < col.length; x++) {
							const pixel = col[x];
							// p5.stroke('purple');
							if (!pixel) continue;
							// p5.stroke(p5.map(pixel, 0, 4, 0, 100));
							// p5.strokeWeight(p5.map(pixel, 0, 4, 0, 100));
							p5.strokeWeight(1);
							p5.point(x, y);
							// p5.fill("purple");
							// p5.text(pixel, x, y);
						}
					}
					// meMysterious = p5.loadImage('/images/amane.png')

					var x = 0;

					for (var i = 0; i <= p5.width / symbolSize; i++) {
						for (var j = 0; j < 1; j++) {
							var stream = new Stream();
							// stream.generateSymbols(x, p5.random(-2000, 0));
							// stream.generateSymbols(x, p5.random(window.innerHeight, window.innerHeight + 2000));
							stream.generateSymbols(x, p5.random(window.innerHeight, window.innerHeight +  2000) );
							streams.push(stream);
						}
						x += symbolSize;
					}

					p5.textFont("Consolas");
					p5.textSize(symbolSize);
				};

				p5.draw = () => {
					p5.background(0, 150);
					// p5.background(image);
					streams.forEach(function (stream) {
						stream.render();
					});
				};

				p5.windowResized = () => {
					p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
				};

				p5.mouseWheel = (evt) => {
					// console.log(">> mouseWheel", evt.deltaY);
					// speedFactor += evt.deltaY > 0 ? .1 : -0.5;

					speedFactor = p5.map(evt.deltaY, -10, 10, -1.5, 1.5);
					// speedFactor = evt.deltaY < 0 ? -1 : 1;

					// console.log("<< speedFactor", speedFactor);
					speedFactor = p5.constrain(speedFactor, -1.3, 1.2);
				};

				function Symbol(x, y, speed, first, opacity) {
					this.x = x;
					this.y = y;
					this.value;

					this.speed = speed;
					this.first = first;
					this.opacity = opacity;

					this.switchInterval = p5.round(p5.random(2, 25));

					this.setToRandomSymbol = function () {
						var charType = p5.round(p5.random(0, 5));
						if (p5.frameCount % this.switchInterval == 0) {
							if (charType > 1) {
								// set it to Katakana
								this.value = String.fromCharCode(0x30a0 + p5.floor(p5.random(0, 97)));
							} else {
								// set it to numeric
								this.value = p5.floor(p5.random(0, 10));
							}
						}
					};

					this.rain = function () {
						// this.y = this.y >= height ? 0 : (this.y += this.speed);
						// this.y = this.y >= height ? 0 : (this.y -= this.speed);
						// this.y = this.y < 0 ? p5.height : (this.y -= this.speed);
						this.y = this.y < 0 ? p5.height : (this.y -= this.speed * speedFactor);
					};
				}

				function calculateOpacity({ x, y, opacity }) {
					let newOpacity = opacity;

					// const spot = imageSpots[x + parseInt(y) * image.width];
					let spot = null;
					try {
						spot = imageSpots[parseInt(y)][x];
					} catch (error) {}
					if (!spot) return newOpacity;
					// console.log("spot found");
					// if(spot.x == x && spot.y == y){
					newOpacity = p5.map(spot.brightness, 0, 100, 0, 255);
					return { checks: true, opacity: newOpacity };
					// }
				}

				function Stream() {
					this.symbols = [];
					this.totalSymbols = p5.round(p5.random(25, 35));
					// this.speed = p5.random(5, 22);
					this.speed = p5.random(5, 22) * speedFactor;

					this.generateSymbols = function (x, y) {
						var opacity = 255;
						var first = p5.round(p5.random(0, 4)) == 1;
						for (var i = 0; i <= this.totalSymbols; i++) {
							let symbol = new Symbol(x, y, this.speed, first, opacity);
							symbol.setToRandomSymbol();
							this.symbols.push(symbol);
							opacity -= opacity / this.totalSymbols / fadeInterval;
							// y -= symbolSize;
							y += symbolSize;
							first = false;
						}
					};

					this.render = function () {
						this.symbols.forEach(function (symbol) {
							let opacity = calculateOpacity(symbol);

							if (!opacity.checks) {
								if (symbol.first) {
									p5.fill(140, 255, 170, opacity);
								} else {
									p5.fill(0, 255, 70, opacity);
								}
							} else {
								// p5.fill(200, 41, 41, 255);
								p5.fill(255);
							}

							p5.text(symbol.value, symbol.x, symbol.y);
							symbol.rain();
							symbol.setToRandomSymbol();
						});
					};
				}
			};

			new P5(script);
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#matrix-rain-canvas {
		display: block;
		margin: 0 auto;
		padding: 0;
	}
</style>
