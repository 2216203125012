import { createRouter, createWebHistory } from "vue-router";
//
//
import Home from "../views/Home.vue";
import MiniProject from "../views/MiniProject.vue";
//
const routes = [
    {
        path: "",
        component: Home,
        name: "Home",
    },
    {
        path: "/mini_projects/view/:id",
        component: MiniProject,
        name: "MiniProject",
    },
    {
        path: "/:pathMatch(.*)*",
        component: Home,
        name: "Home",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
