import { reactive } from "vue";

let state = reactive({
    currentProjectIndex: 0,
    projects: [
        {
            title: "ALaGare",
            description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit quidem ducimus perspiciatis et numquam obcaecati eligendi illum praesentium quo, beatae quasi facere placeat consequatur?",
            link: "http:route-to-link",
            role: "Fullstack",
            type: "Mobile Application",
            stack: [
                {
                    icon: "",
                    title: "Flutter",
                },
                {
                    icon: "",
                    title: "Firebase",
                },
                {
                    icon: "",
                    title: "GeoCoding",
                },
            ],
        },
        {
            title: "ZeroMalaria",
            description: "Lorem ipsum dolor sit amet.",
            link: "http:route-to-link",
            role: "Fullstack Dev",
            stack: [
                {
                    icon: "",
                    title: "Vue",
                },
                {
                    icon: "",
                    title: "JS",
                },
                {
                    icon: "",
                    title: "p5.js",
                },
            ],
        },
    ],
});

export default {
    state,
    mutations: {},
    actions: {},
    modules: {},
};
