<template>
	<article v-show="isPortfolioProjectSelected" class="absolute z-10 w-full h-full bg-bleu border rounded border-opacity-20">
		<!-- <aside class="col-span-3 from-bleu bg-gradient-to-lb to-blue-500 bg-opacity-10">
										<div>Overview</div>
										<div>Story</div>
										<div>ScreenShots</div>
										<div>Features</div>
										<div>Tech</div>
									</aside> -->
		<section v-if="!project" class="flex flex-col justify-center items-center text-center gap-3 h-full">
			<ion-icon name="fish-outline" style="font-size: 64px"></ion-icon>
			<h3 class="font-semibold text-lg text-white">This project doesn't exist or has been deleted</h3>

			<button @click="closePortfolioProject" class="hover:bg-white hover:bg-opacity-10 hover:text-white px-3 py-2">Go Back</button>
		</section>
		<main v-else class="col-span-12 flex flex-col h-full justify-between overflow-scroll rounded">
			<nav class="flex justify-between items-start gap-3 p-3 shadow-md sticky top-0 bg-bleu">
				<div class="flex gap-2">
					<img :src="project.logo" class="w-10 h-10" alt="" />
					<div class="flex flex-col text-left">
						<h3 class="font-semibold text-white">{{ project?.name }}</h3>
						<p>{{ project?.platform?.name }}</p>
					</div>
				</div>
				<span
					@click="closePortfolioProject"
					title="Fermer le project"
					class="hover:text-red-500 cursor-pointer p-1 flex items-center justify-center hover:bg-white hover:bg-opacity-10">
					<ion-icon name="close-outline" size="large"></ion-icon>
				</span>
			</nav>
			<section class="p-3 text-left">
				<h2 class="text-white">Description</h2>
				<div class="rounded border border-opacity-10 bg-black bg-opacity-10 p-2">
					<div v-if="!project.full_description" class="text-center p-10 flex justify-center items-center w-full">
						<h4>No description</h4>
					</div>
					<p v-else>
						{{ project.full_description }}
					</p>
				</div>
			</section>
			<section class="p-3 text-left">
				<h2 class="text-white">Screenshots</h2>
				<div class="rounded border border-opacity-10 bg-black bg-opacity-10 overflow-scroll flex gap-4 p-2 pb-3" :class="[screenshots?.length > 0  ? '' : 'h-full']">
					<div v-if="screenshots.length == 0" class="text-center p-10 flex justify-center items-center w-full">
						<h4>No image</h4>
					</div>
					<img
						v-else
						v-for="(image, index) in screenshots"
						@contextmenu="
							(e) => {
								e.preventDefault();
							}
						"
						:key="index"
						:src="image"
						class=" h-auto rounded object-contain w-32"
						:class="{
							'w-80 object-contain bg-white' : project?.platform?.id === 'web',
							'w-28' : project?.platform?.id === 'mobile',

						}" />

				</div>
			</section>
			<section class="p-3 text-left mt-3">
				<h2 class="text-white">Features</h2>
				<div class="rounded border border-opacity-10 bg-black bg-opacity-10 overflow-scroll flex gap-4 gap-y-2 p-2 pb-3" :class="[screenshots?.length > 0 ? '' : 'h-full']">
					<div v-if="!project.features" class="text-center p-10 flex flex-col justify-center items-center w-full gap-3">
						<ion-icon v-if="project.hide_features" name="lock-closed-outline" size="large"></ion-icon>
						<h4 v-if="project.hide_features">Can't say, signed an NDA </h4>
						<h4 v-else>No Details</h4>
					</div>
					<div v-else class="flex flex-wrap justify-center gap-y-2 gap-2 py-3">
						<div v-for="(feature, i) in project.features" :key="i" class="flex flex-col items-center gap-1 text-center">
							<!-- <div class="w-16 h-16 rounded bg-blue-500"></div> -->
							<span class="rounded border border-opacity-20 bg-black bg-opacity-25 px-2 py-1 hover:bg-bleu transition-colors duration-200 ease-out">

							{{feature?.name ?? feature}}
							</span>
						</div>
					</div>
				</div>
			</section>
			<footer class="flex justify-between items-end mt-3">
				<div class="p-3 text-left">
					<h2 v-if="project.tech_stack" class="text-white">Tech stack</h2>
					<div class="mt-3 flex gap-3">
						<div v-for="(stack, i) in project.tech_stack" :key="i" class="flex flex-col items-center">
							<span v-if="!getTechStack(stack).logo" class="w-5 h-5">
								<!-- <ion-icon  name="code-slash-outline" ></ion-icon> -->
							</span>
							<img v-else :src="getTechStack(stack).logo" class="w-5 h-5 object-contain" alt="" />
							<span class="capitalize">{{ getTechStack(stack).name }}</span>
						</div>
					</div>
				</div>
				<div v-if="project.links" class="p-3 text-right">
					<h2 class="text-white">Links</h2>
					<div class="mt-3 flex gap-3">
						<template v-for="(link, i) in project.links" :key="i">
							<a v-if="link.provider == 'playstore'" :href="link.url" target="_blank" class="flex items-center gap-2 bg-black px-3 py-2 rounded-lg shadow text-white">
								<ion-icon name="logo-google-playstore"></ion-icon>
								<div class="flex flex-col text-left">
									<span class="text-xs font-semibold whitespace-nowrap" style="font-size: 8px">GET IT ON</span>
									<span class="text-sm font-semibold whitespace-nowrap">Google Play</span>
								</div>
							</a>
							<a v-else-if="link.provider == 'applestore'" :href="link.url" target="_blank" class="flex items-center gap-2 bg-black px-3 py-2 rounded-lg shadow text-white">
								<ion-icon name="logo-apple"></ion-icon>
								<div class="flex flex-col">
									<span class="text-xs font-semibold whitespace-nowrap" style="font-size: 8px">Download on the</span>
									<span class="text-sm font-semibold whitespace-nowrap">App Store</span>
								</div>
							</a>
							<a v-else :href="link.url" target="_blank" class="flex items-center gap-2 bg-black px-2 py-1 rounded shadow text-white">
								Launch 
								<ion-icon name="earth-outline"></ion-icon>
							</a>
						</template>
					</div>
				</div>
			</footer>
		</main>
	</article>
</template>

<script>
	export default {
		name: "PortfolioProjectDetailsCard",
		props: {
			isPortfolioProjectSelected: Boolean,
			project: Object,
		},
		data: () => ({
			links: [
				{
					name: "Play store",
					url: "https://play.google.com/store/apps/details?id=com.bleu",
				},
				{
					name: "App store",
					url: "https://apps.apple.com/us/app/bleu/id1560000000",
				},
			],
			features: [
				{
					name: "Authentification",
				},
				{
					name: "Notifications",
				},
				{
					name: "Géolocalisation",
				},
				{
					name: "Chat",
				},
				{
					name: "Appels",
				},
				{
					name: "Vidéos",
				},
				{
					name: "Audio",
				},
				{
					name: "Partage",
				},
				{
					name: "Synchronisation",
				},
				{
					name: "Stockage",
				},
				{
					name: "Base de données",
				},
				{
					name: "API",
				},
				{
					name: "Websockets",
				},
				{
					name: "PWA",
				},
				{
					name: "SEO",
				},
				{
					name: "Responsive",
				},
				{
					name: "Dark mode",
				},
				{
					name: "Light mode",
				},
				{
					name: "Theming",
				},
				{
					name: "Animations",
				},
				{
					name: "Micro-interactions",
				},
				{
					name: "Design system",
				},
				{
					name: "UI/UX",
				},
				{
					name: "Tests",
				},
				{
					name: "CI/CD",
				},
				{
					name: "Monitoring",
				},
				{
					name: "Analytics",
				},
				{
					name: "SEO",
				},
				{
					name: "Accessibility",
				},
				{
					name: "Performance",
				},
				{
					name: "Security",
				},
				{
					name: "Privacy",
				},
				{
					name: "Legal",
				},
				{
					name: "Compliance",
				},
				{
					name: "Internationalization",
				},
				{
					name: "Localization",
				},
				{
					name: "Translation",
				},
				{
					name: "Multilingual",
				},
			],
			stacks: [
				{
					name: "Firebase",
					logo: "/logo/firebase.png",
					key: "firebase",
				},
				{
					name: "Flutter",
					logo: "/logo/flutter.png",
					key: "flutter",
				},
				{
					name: "NodeJS",
					logo: "/logo/nodejs.png",
					key: "nodejs",
				},
				{
					name: "MongoDB",
					logo: "/logo/mongodb.png",
					key: "mongodb",
				},
				{
					name: "ExpressJS",
					logo: "/logo/expressjs.png",
					key: "expressjs",
				},
				{
					name: "ReactJS",
					logo: "/logo/reactjs.png",
					key: "reactjs",
				},
				{
					name: "VueJS",
					logo: "/logo/vuejs.png",
					key: "vuejs",
				},
				{
					name: "NuxtJS",
					logo: "/logo/nuxtjs.svg",
					key: "nuxtjs",
				},
				{
					name: "TailwindCSS",
					logo: "/logo/tailwindcss.png",
					key: "tailwindcss",
				},
				{
					name: "Vuetify",
					logo: "/logo/vuetify.png",
					key: "vuetify",
				},
				{
					name: "Bootstrap",
					logo: "/logo/bootstrap.png",
					key: "bootstrap",
				},
				{
					name: "Sass",
					logo: "/logo/sass.png",
					key: "sass",
				},
				{
					name: "HTML5",
					logo: "/logo/html5.png",
					key: "html5",
				},
				{
					name: "CSS3",
					logo: "/logo/css3.png",
					key: "css3",
				},
				{
					name: "JavaScript",
					logo: "/logo/javascript.png",
					key: "javascript",
				},
				{
					name: "TypeScript",
					logo: "/logo/typescript.png",
					key: "typescript",
				},
				{
					name: "Python",
					logo: "/logo/python.png",
					key: "python",
				},
				{
					name: "Django",
					logo: "/logo/django.png",
					key: "django",
				},
				{
					name: "Django Rest Framework",
					logo: "/logo/django-rest-framework.png",
					key: "django-rest-framework",
				},
				{
					name: "PostgreSQL",
					logo: "/logo/postgresql.png",
					key: "postgresql",
				},
			],
		}),
		computed: {
			screenshots(){
				return new Array(this.project.screenshots_data?.count ?? 0).fill(null).map((_, i) => `${this.project.screenshots_data.base_url}${i+1}${this.project.screenshots_data.ext}`);
			},
			techStacks() {
				return this.stacks.filter((stack) => this.project.technologies.includes(stack.key));
			},
		},
		methods: {
			getTechStack(key) {
				return this.stacks.find((stack) => stack.key === key) ?? { key, name: key, logo: null };
			},
			closePortfolioProject() {
				this.$emit("closePortfolioProject");
			},
		},
	};
</script>

<style></style>
