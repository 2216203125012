<template>
	<router-view class="layout-container" />
</template>

<script>
	import store from "../src/store/index.js";
	import contact from "../src/store/contact.js";
	export default {
		name: "App",
		provide: { ss: store, contact: contact },

		mounted() {
			window.onscroll = function () {
				scrollController();
			};

			function scrollController() {
				// console.log(">> oncroll");

				let mainContentElement = document.getElementById("main-content");

				if (mainContentElement) {
					if (document.documentElement.scrollTop >= mainContentElement.getBoundingClientRect().top) {
						// console.log(">> details");
					} else {
						// console.log(">> close detilas");
					}
				}
			}
		},
	};
</script>

<style lang="scss">
	:root {
		--blue: #1e3d59;
		--orange: #ff6e40;
		--beige: #f5f0e1;
		--yellow: #ffc13b;
	}
	html,
	body {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		height: 100vh;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 0;
		padding: 0;
		// cursor: none !important;
	}
	/* width */
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		padding: 5px;
		display: none;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 5px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #cecece;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #cecece;
	}
	.layout-container {
		min-height: 100vh !important;
	}

	#nav {
		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	.button {
		position: relative;
		/* background: #333; */
		/* background: var(--primary); */
		background: transparent;
		color: #ccc;
		// width: 200px;
		height: 50px;
		border: 0;
		font-size: 18px;
		// border-radius: 4px;
		transition: 0.6s;
		overflow: hidden;
	}
	.button.btn {
		width: auto;
		// max-width: 230px !important;
	}

	.button:focus {
		outline: 0;
	}

	.button:before {
		content: "";
		display: block;
		position: absolute;
		background: rgba(255, 255, 255, 0.5);
		width: 60px;
		height: 100%;
		left: 0;
		top: 0;
		opacity: 0.5;
		filter: blur(30px);
		transform: translateX(-100px) skewX(-15deg);
	}

	.button:after {
		content: "";
		display: block;
		position: absolute;
		background: rgba(255, 255, 255, 0.2);
		width: 30px;
		height: 100%;
		left: 30px;
		top: 0;
		opacity: 0;
		filter: blur(5px);
		transform: translateX(-100px) skewX(-15deg);
	}

	.button:hover {
		// background: #338033;
		background: var(--orange);
	}

	.button:hover:before {
		transform: translateX(400px) skewX(-15deg);
		opacity: 0.6;
		transition: 0.7s;
	}

	.button:hover:after {
		transform: translateX(400px) skewX(-15deg);
		opacity: 1;
		transition: 0.7s;
	}

	/// Transitions

	// / App Transitions
	.slide-enter-from,
	.slide-leave-to {
		opacity: 0;
		transform: translateX(100%);
	}
	.slide-enter-active,
	.slide-leave-active {
		transition: all 0.3s ease-out;
	}

	.slide-fade-up-enter-from,
	.slide-fade-up-leave-to {
		opacity: 0;
		transform: translateY(40px);
	}

	.slide-fade-up-enter-active,
	.slide-fade-up-leave-active {
		transition: opacity 0.9s ease-out, transform 1s ease-out;
	}
	.slide-fade-up-delay-enter-from,
	.slide-fade-up-delay-leave-to {
		opacity: 0;
		transform: translateY(40px);
	}

	.slide-fade-up-delay-enter-active,
	.slide-fade-up-delay-leave-active {
		transition: opacity 0.9s ease-out 0.2s, transform 1s ease-out 0.2s;
	}

	// Slides from the top to the bottom
	.slide-down-enter-from,
	.slide-down-leave-to {
		opacity: 0;
		transform: translateY(-30px);
	}

	.slide-down-enter-active,
	.slide-down-leave-active {
		transition: all 0.3s ease-out;
	}
	// Slides from the bottom to the top
	.slide-up-enter-from,
	.slide-up-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}

	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: all 0.2s ease-out;
	}

	// Fades in
	.fade-in-enter-from,
	.fade-in-leave-to {
		opacity: 0;
	}
	.fade-in-enter-active {
		transition: opacity 0.3s ease-out;
	}
	.fade-in-leave-active {
		transition: opacity 0.2s ease-out;
	}

	.fade-in-delay-enter-from,
	.fade-in-delay-leave-to {
		opacity: 0;
	}
	.fade-in-delay-enter-active {
		transition: opacity 0.3s ease-out 0.2s;
	}
	.fade-in-delay-leave-active {
		transition: opacity 0.2s ease-out;
	}

	.slide-right-enter-from {
		transform: translateX(300px);
	}
	.slide-right-leave-to {
		opacity: 0;
		transform: translateX(300px);
	}
	.slide-right-enter-active {
		transition: all 0.4s ease-out 0.2s;
	}
	.slide-right-leave-active {
		transition: all 0.3s ease-in;
	}

	//

	.fade-up-enter-from,
	.fade-up-leave-to {
		opacity: 0;
		transform: translateY(20px);
	}

	.fade-up-enter-active,
	.fade-up-leave-active {
		transition: opacity 0.3s ease-out, transform 0.4s ease-out;
	}
	.fade-up-delay-enter-from,
	.fade-up-delay-leave-to {
		opacity: 0;
		transform: translateY(10px);
	}

	.fade-up-delay-enter-active,
	.fade-up-delay-leave-active {
		transition: opacity 0.3s ease-out 0.2s, transform 0.4s ease-out 0.2s;
	}
</style>
